import React from 'react'
import { graphql } from 'gatsby'
import { Row, Col } from 'reactstrap'

import { EventDateRange } from '../components/atoms/EventDateRange'
import GoogleMapLink from '../components/atoms/googleMapLink'
import { SEO } from '../components/atoms/seo'
import { formatMoney, formatDate } from '../_utils'
import Layout from '../components/layout'

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        author {
          first_name
          last_name
        }
        image {
          publicURL
        }
        type
        start_date(formatString: "YYYY-MM-DD")
        end_date(formatString: "YYYY-MM-DD")
        start_time
        end_time
        cost
        location_name
        location_address
        geojson {
          lat
          lon
        }
        tags
      }
      excerpt
      html
      fields {
        slug
      }
    }
  }
`

const CourseTemplate = ({ data, props }) => {
  // console.log('course template: ', data)

  return (
    <>
      <Layout
        location={data?.markdownRemark?.fields?.slug}
        pageType={data?.markdownRemark?.frontmatter?.type}
      >
        <Row className="heading">
          <Col
            xs={{ size: 10, offset: 1 }}
            md={{ size: 8, offset: 2 }}
            lg={{ size: 6, offset: 3 }}
          >
            <h1>{data?.markdownRemark?.frontmatter?.title}</h1>
          </Col>
        </Row>

        <Row className="content">
          <Col
            xs={{ size: 10, offset: 1 }}
            md={{ size: 8, offset: 2 }}
            lg={{ size: 6, offset: 3 }}
          >
            <div className="course-meta">
              <div className="date">
                {data?.markdownRemark?.frontmatter?.date}
              </div>
              <EventDateRange
                startDate={data?.markdownRemark?.frontmatter?.start_date}
                endDate={data?.markdownRemark?.frontmatter?.end_date}
                startTime={data?.markdownRemark?.frontmatter?.start_time}
                endTime={data?.markdownRemark?.frontmatter?.end_time}
              />
              <GoogleMapLink
                geojson={data?.markdownRemark?.frontmatter?.geojson}
                locationName={data?.markdownRemark?.frontmatter?.location_name}
                locationAddress={
                  data?.markdownRemark?.frontmatter?.location_address
                }
              />
              <div className="cost">
                {`${data?.markdownRemark?.frontmatter?.cost}`}
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: data?.markdownRemark?.html }}
            ></div>
          </Col>
        </Row>
      </Layout>
    </>
  )
}

export default CourseTemplate

export const Head = ({ data }) => {
  const pageMeta = {
    title: data?.markdownRemark?.frontmatter?.title,
    type: 'courses',
    description: data?.markdownRemark?.excerpt,
    keywords: data?.markdownRemark?.frontmatter?.tags,
    location: data?.markdownRemark?.fields?.slug,
    image: data?.markdownRemark?.frontmatter?.image?.publicURL,
    url: `${data?.site?.siteMetadata?.siteUrl}${data?.markdownRemark?.fields?.slug}`,
  }
  return <SEO meta={{ ...pageMeta }} />
}
